<template>
    <v-container v-bind:class="{ 'pa-0': $vuetify.breakpoint.smAndDown }">
        <v-card class="overflow-hidden" elevation="1" v-bind:class="{ 'rounded-lg': !$vuetify.breakpoint.smAndDown }">
            <v-card-title>
                <v-icon left color="primary">mdi-credit-card-clock</v-icon>
                Planes de Suscripción

                <v-spacer />

                <!-- Search field for desktop -->
                <v-text-field v-show="!$vuetify.breakpoint.smAndDown" rounded filled class="mr-4 rounded-lg"
                    v-model="search" dense append-icon="mdi-magnify" label="Buscar" single-line
                    hide-details></v-text-field>

                <v-btn v-show="!$vuetify.breakpoint.smAndDown" color="primary" class="rounded-lg"
                    @click="openCreateDialog">
                    <v-icon left>mdi-plus</v-icon>
                    Nuevo Plan
                </v-btn>
            </v-card-title>

            <!-- Mobile search and add button -->
            <v-divider v-show="$vuetify.breakpoint.smAndDown" />
            <v-card-title v-show="$vuetify.breakpoint.smAndDown">
                <v-text-field filled rounded class="rounded-lg mr-4" v-model="search" dense append-icon="mdi-magnify"
                    style="width: 180px;" label="Buscar" single-line hide-details></v-text-field>
                <v-btn color="primary" class="rounded-lg" @click="openCreateDialog">
                    <v-icon left>mdi-plus</v-icon>
                    Nuevo
                </v-btn>
            </v-card-title>

            <v-divider />

            <!-- Plans List -->
            <v-data-table :headers="headers" :items="plans" :search="search" :loading="loading" :items-per-page="10"
                :no-data-text="'No se encontraron planes'" :no-results-text="'No se encontraron coincidencias'"
                class="elevation-0">
                <template v-slot:[`item.auto_recurring.transaction_amount`]="{ item }">
                    ${{ item.auto_recurring.transaction_amount }}
                </template>

                <template v-slot:[`item.auto_recurring.frequency`]="{ item }">
                    {{ item.auto_recurring.frequency }} {{ item.auto_recurring.frequency_type }}
                </template>

                <template v-slot:[`item.daysPerWeek`]="{ item }">
                    <v-chip :color="item.daysPerWeek === 0 ? 'warning' : 'primary'" small>
                        {{ item.daysPerWeek === 0 ? 'Free Pass' : `${item.daysPerWeek} días` }}
                    </v-chip>
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon @click="editPlan(item)" v-bind="attrs" v-on="on">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                        <span>Editar plan</span>
                    </v-tooltip>
                </template>
            </v-data-table>

            <!-- Create/Edit Plan Dialog -->
            <v-dialog v-model="editDialog" max-width="500px" :fullscreen="$vuetify.breakpoint.smAndDown">
                <v-card>
                    <v-card-title>
                        <v-btn icon class="mr-4" @click="closeEditDialog">
                            <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                        {{ dialogTitle }}
                    </v-card-title>

                    <v-divider />
                    <v-card-text class="px-10 pt-4">
                        <v-form ref="editForm" v-model="editValid" @submit.prevent="savePlan">
                            <v-text-field prepend-icon="mdi-tag" v-model="editedPlan.reason"
                                :rules="[v => !!v || 'El nombre es requerido']" label="Nombre del Plan"
                                required></v-text-field>

                            <v-text-field prepend-icon="mdi-currency-usd"
                                v-model.number="editedPlan.auto_recurring.transaction_amount" :rules="[
                                    v => !!v || 'El monto es requerido',
                                    v => v > 0 || 'El monto debe ser positivo'
                                ]" label="Monto" type="number" required></v-text-field>

                            <v-select prepend-icon="mdi-calendar-clock"
                                v-model="editedPlan.auto_recurring.frequency_type" :items="frequencyTypes"
                                label="Tipo de Frecuencia" required></v-select>

                            <v-text-field prepend-icon="mdi-numeric"
                                v-model.number="editedPlan.auto_recurring.frequency" :rules="[
                                    v => !!v || 'La frecuencia es requerida',
                                    v => v > 0 || 'La frecuencia debe ser positiva'
                                ]" label="Frecuencia" type="number" required></v-text-field>

                            <v-subheader class="pl-0">Días por Semana</v-subheader>
                            <v-radio-group v-model="editedPlan.daysPerWeek" row dense required>
                                <v-radio label="1" :value="1"></v-radio>
                                <v-radio label="2" :value="2"></v-radio>
                                <v-radio label="3" :value="3"></v-radio>
                                <v-radio label="4" :value="4"></v-radio>
                                <v-radio label="5" :value="5"></v-radio>
                                <v-radio label="6" :value="6"></v-radio>
                                <v-radio label="Free Pass" :value="0"></v-radio>
                            </v-radio-group>
                        </v-form>
                    </v-card-text>

                    <v-divider v-show="!$vuetify.breakpoint.smAndDown" />
                    <v-card-actions v-show="!$vuetify.breakpoint.smAndDown">
                        <v-spacer></v-spacer>
                        <v-btn text @click="closeEditDialog">Cancelar</v-btn>
                        <v-btn :disabled="!editValid" :loading="loading" color="primary" @click="savePlan">
                            {{ editedPlan.id ? 'Actualizar' : 'Crear' }}
                        </v-btn>
                    </v-card-actions>

                    <!-- Mobile actions -->
                    <v-card-actions v-show="$vuetify.breakpoint.smAndDown">
                        <v-btn x-large :disabled="!editValid" :loading="loading" color="primary" @click="savePlan"
                            block>
                            {{ editedPlan.id ? 'Actualizar' : 'Crear' }}
                        </v-btn>
                    </v-card-actions>
                    <v-card-actions v-show="$vuetify.breakpoint.smAndDown">
                        <v-btn large @click="closeEditDialog" block>Cancelar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card>
    </v-container>
</template>

<script>
import { getFunctions, httpsCallable } from 'firebase/functions'
import { getFirestore, collection, onSnapshot } from 'firebase/firestore'

export default {
    name: 'SubscriptionPlansManager',

    data: () => ({
        loading: false,
        search: '',
        plans: [],
        editDialog: false,
        editValid: false,
        dialogTitle: 'Nuevo Plan de Suscripción',
        editedPlan: {
            reason: '',
            auto_recurring: {
                frequency: 1,
                frequency_type: 'months',
                transaction_amount: 0,
                currency_id: 'UYU'
            },
            back_url: 'https://your-site.com/success',
            daysPerWeek: 0
        },
        frequencyTypes: [
            { text: 'Días', value: 'days' },
            { text: 'Meses', value: 'months' }
        ],
        headers: [
            { text: 'Nombre del Plan', value: 'reason' },
            { text: 'Monto', value: 'auto_recurring.transaction_amount' },
            { text: 'Frecuencia', value: 'auto_recurring.frequency' },
            { text: 'Días/Semana', value: 'daysPerWeek' },
            { text: 'Acciones', value: 'actions', sortable: false }
        ],
        snackbar: {
            show: false,
            text: '',
            color: 'success'
        },
        unsubscribe: null
    }),

    mounted() {
        this.subscribeToPlans()
    },

    beforeDestroy() {
        if (this.unsubscribe) {
            this.unsubscribe()
        }
    },

    methods: {
        openCreateDialog() {
            this.dialogTitle = 'Nuevo Plan de Suscripción'
            this.editedPlan = {
                reason: '',
                auto_recurring: {
                    frequency: 1,
                    frequency_type: 'months',
                    transaction_amount: 0,
                    currency_id: 'UYU'
                },
                back_url: 'https://your-site.com/success',
                daysPerWeek: 0
            }
            this.editDialog = true
        },

        closeEditDialog() {
            this.editDialog = false
            if (this.$refs.editForm) {
                this.$refs.editForm.reset()
            }
            this.editedPlan = {
                reason: '',
                auto_recurring: {
                    frequency: 1,
                    frequency_type: 'months',
                    transaction_amount: 0,
                    currency_id: 'UYU'
                },
                back_url: 'https://your-site.com/success',
                daysPerWeek: 0
            }
            this.dialogTitle = 'Nuevo Plan de Suscripción'
        },

        editPlan(plan) {
            this.dialogTitle = 'Editar Plan de Suscripción'
            this.editedPlan = {
                id: plan.id,
                reason: plan.reason,
                auto_recurring: {
                    frequency: plan.auto_recurring.frequency,
                    frequency_type: plan.auto_recurring.frequency_type,
                    transaction_amount: plan.auto_recurring.transaction_amount,
                    currency_id: plan.auto_recurring.currency_id || 'UYU'
                },
                back_url: plan.back_url || 'https://your-site.com/success',
                daysPerWeek: plan.daysPerWeek
            }
            this.editDialog = true
        },

        async savePlan() {
            this.loading = true
            try {
                await this.$refs.editForm.validate()
                const functions = getFunctions()
                const functionName = this.editedPlan.id ? 'gym/updatePlan' : 'gym/createPlan'
                const planFunction = httpsCallable(functions, functionName)

                const result = await planFunction(this.editedPlan)

                if (result.data) {

                    this.notify({
                        title: `Plan ${this.editedPlan.id ? 'actualizado' : 'creado'} exitosamente`,
                        type: 'success'
                    })

                    this.closeEditDialog()
                }
            } catch (error) {
                console.error('Error saving plan:', error)
                this.notify({
                    title: 'Error',
                    text: error.message || 'Error al guardar el plan',
                    type: 'error'
                })
            } finally {
                this.loading = false
            }
        },

        subscribeToPlans() {
            const db = getFirestore()
            const plansRef = collection(db, 'mercadoPagoSubscriptions')
            this.unsubscribe = onSnapshot(plansRef, (snapshot) => {
                this.plans = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }))
            }, (error) => {
                console.error('Error fetching plans:', error)
                this.notify({
                    title: 'Error',
                    text: 'Error al cargar los planes',
                    type: 'error'
                })
            })
        }
    }
}
</script>

<style scoped>
.v-data-table {
    width: 100%;
}

.v-card {
    transition: all 0.3s ease;
}

.v-btn {
    text-transform: none;
}

.v-radio-group {
    margin-top: 8px;
}

.v-subheader {
    height: 36px;
    font-size: 0.875rem;
    font-weight: 500;
}

.rounded-lg {
    border-radius: 8px !important;
}

@media (max-width: 600px) {
    .v-card-title {
        padding: 16px;
    }

    .v-card-text {
        padding: 16px !important;
    }
}
</style>